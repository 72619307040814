<template>
    <div>
        <TableLayout
            :module="module"
            ref="TableLayout"
            :table-columns="tableColumns"
            initSortField="isin"
            initSortOrder="asc"
            :rights="rights"
            :filters="filters"
            :additionalOperButtonsWidth="40"
            no-table-del
            no-table-edit
            v-if="loaded">

            <template v-slot:additional_oper_buttons="{row}">
                <el-button
                    type="text"
                    @click="openBond(row)"
                    size="mini"
                    class="tl-oper_button oper_button_instrument">
                    <svg-icon icon-class="chevron_right" />
                </el-button>
            </template>

        </TableLayout>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TableLayout from '@/components/TableLayout'
import tab from '@/mixins/tab'

export default {
    components: { TableLayout },
    mixins: [tab],

    data() {
        return {
            module: 'instrumentBond',
            loaded: false,
            rights: {
                view : true,
                edit : false,
                add  : false,
                del  : false,
            },
            filters: {
                isin         : { value: undefined },
                name         : { value: undefined },
                issuer_name  : { value: undefined },
                currency     : { value: undefined, strict: true },
                maturity     : { value: undefined },
                payment_rank : { value: undefined },
            },
            currency_options: []
        }
    },

    computed: {
        ...mapState({
            currency_list : state => state.currency.list,
        }),

        tableColumns(){
            let columns = [
                {
                    field  : 'isin', 
                    title  : 'ISIN', 
                    width  : 130, 
                    fixed  : 'left',
                    params : {
                        filter : 'input', 
                    }, 
                },{
                    field  : 'name', 
                    title  : 'Name', 
                    minWidth  : 140, 
                    params : {
                        filter : 'input', 
                    }, 
                },{
                    field  : 'issuer_name', 
                    title  : 'Issuer', 
                    minWidth  : 140, 
                    params : {
                        filter : 'input', 
                    }, 
                },{
                    field  : 'currency', 
                    title  : 'Currency', 
                    width  : 100, 
                    params : {
                        filter  : 'select',
                        options : this.currency_options,
                    }, 
                },{
                    field  : 'maturity', 
                    title  : 'Maturity', 
                    width  : 140, 
                    formatter: 'formatDate',
                    params : {
                        filter : 'date',
                    }, 
                },{
                    field  : 'payment_rank', 
                    title  : 'Payment Rank', 
                    width  : 110, 
                    params : {
                        filter : 'input', 
                    }, 
                },
            ]

            return columns
        }
    },

    methods: {
        openBond(row) {
            this.$router.push('/library/instruments/bond/' + row.isin)
        }
    },

    mounted(){
        this.$store.dispatch('currency/getList').then(() => {
            this.currency_options = []
            this.currency_list.forEach(c => {
                this.currency_options.push({ label: c.code, value: c.code })
            })
            this.loaded = true
        });
    },
}

</script>
